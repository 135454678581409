<template>
  <div>
    <v-text-field
      label="Price *"
      v-model="fields.price"
      type="number"
      step="0.01"
      prefix="£"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('price')"
      :error-messages="errors['price']"
    ></v-text-field>
    <v-select
      label="Type *"
      v-model="fields.price_type"
      :items="types"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('price_type')"
      :error-messages="errors['price_type']"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      fields: {
        price: null,
        price_type: null,
      },
      types: ["Fixed Price", "Offers Over", "Offers Around"],
      errors: {},
    };
  },

  methods: {
    reset() {
      this.fields.price = null;
      this.fields.price_type = null;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.priceId = this.property.price.id;
      }

      this.$store
        .dispatch("melrose/properties/savePrice", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    error(errors) {
      this.errors = errors;
    },
  },
};
</script>
