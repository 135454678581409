<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Properties</h1>
        </v-col>
        <v-col cols="auto" class="pr-2">
          <v-btn depressed color="accent" class="mr-2" @click="switchView()">
            <span>{{ buttonSwitchViewText }}</span>
          </v-btn>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.propertyDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Property</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Properties</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="grid-row" v-if="isGridView">
            <v-data-table
              :headers="tableHeaders"
              :items="properties"
              no-data-text="No properties"
            >
              <template v-slot:item.address="{ item }">
                {{ item.address.city }} {{ item.address.short }}
                <span class="green--text" v-if="item.featured_property"
                  >** Featured **</span
                >

                <span class="green--text" v-if="!item.visible">- Draft</span>
              </template>
              <template v-slot:item.price="{ item }">
                {{ item.current_price.formatted_price }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  bottom
                  x-small
                  depressed
                  class="mr-2"
                  color="red lighten-4 red--text"
                  @click="openDelete(item)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>

                <v-btn
                  x-small
                  depressed
                  color="green lighten-4 green--text"
                  class=""
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>

          <v-row v-if="!isGridView" class="list-row">
            <v-col cols="2" v-for="property in properties" :key="property.id">
              <v-card>
                <v-img
                  v-if="property.main_image"
                  :src="property.main_image.asset_urls.url"
                  height="200px"
                ></v-img>
                <v-card-title>{{ property.address.city }}</v-card-title>
                <v-card-subtitle>{{ property.address.short }}</v-card-subtitle>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <td>{{ property.status }}</td>
                    </tr>
                    <tr>
                      <th>Customer</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'module-melrose-customers-individual',
                            params: { customerId: property.customer.id },
                          }"
                          >{{ property.customer.full_name }}</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Price</th>
                      <td>
                        {{ property.current_price.type }} £{{
                          property.current_price.formatted_price
                        }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="red lighten-4 red--text"
                    @click="openDelete(item)"
                  >
                    <v-icon left>mdi-archive</v-icon>Archive </v-btn
                  ><br />

                  <v-btn
                    :to="{
                      name: 'module-melrose-properties-individual',
                      params: { propertyId: property.id },
                    }"
                    small
                    depressed
                    color="green lighten-4 green--text"
                    ><v-icon left>mdi-eye</v-icon>View</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <property-dialog ref="propertyDialog" />
    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PropertyDialog from "./components/PropertyDialog.vue";

export default {
  components: {
    PropertyDialog,
  },

  data() {
    return {
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },
      gridData: this.properties,
      buttonSwitchViewText: "Switch to Grid",
      isGridView: true,
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Address", value: "address" },
        { text: "Status", value: "status" },
        { text: "Customer", value: "customer.full_name" },
        { text: "Type", value: "type" },
        { text: "Price", value: "price" },
        { text: "Viewing Done By?", value: "viewings_performed_by" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["melrose/propertiesStore/all"];

      if (this.searchTerm !== "") {
        properties = properties.filter((p) => {
          const short = p.address.short.toLowerCase();
          const customerName = p.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            short.includes(searchTerm) || customerName.includes(searchTerm)
          );
        });
      }

      return properties;
    },
  },

  methods: {
    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },

    switchView: function () {
      if (this.isGridView) {
        this.buttonSwitchViewText = "Switch to List";
      } else {
        this.buttonSwitchViewText = "Switch to Grid";
      }

      this.isGridView = !this.isGridView;
    },
  },
};
</script>
