<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"> Add a new Property </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-alert type="error" icon="mdi-alert-circle" v-if="errorMessage">{{
          errorMessage
        }}</v-alert>

        <v-form @submit.prevent="save" method="post" id="property-form">
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-stepper-step :complete="stepper > 1" step="1" editable
                >Property Information</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="stepper > 2" step="2" editable
                >Address</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="stepper > 3" step="3" editable
                >Price</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="stepper > 4" step="4" editable
                >Image</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <property-details ref="propertyDetails" :property="property" />
                <v-btn color="accent" @click="stepper = 2">Next</v-btn>
                <v-btn text disabled>Back</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <property-address ref="propertyAddress" :property="property" />
                <v-btn color="accent" @click="stepper = 3">Next</v-btn>
                <v-btn text @click="stepper = 1">Back</v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <property-price ref="propertyPrice" :property="property" />
                <v-btn color="accent" @click="stepper = 4">Next</v-btn>
                <v-btn text @click="stepper = 2">Back</v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <property-images ref="propertyImages" :property="property" />
                <v-btn text @click="stepper = 3">Back</v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="property-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PropertyAddress from "./forms/PropertyAddress.vue";
import PropertyDetails from "./forms/PropertyDetails.vue";
import PropertyPrice from "./forms/PropertyPrice.vue";
import PropertyImages from "./forms/PropertyImages.vue";

export default {
  components: {
    PropertyDetails,
    PropertyPrice,
    PropertyImages,
    PropertyAddress,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      property: {},
      stepper: 1,
      errors: {},
      errorMessage: null,
    };
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: {
          ...this.$refs.propertyDetails.fields,
          ...this.$refs.propertyPrice.fields,
          ...this.$refs.propertyAddress.fields,
          ...this.$refs.propertyImages.fields,
        },
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveProperty", payload)
        .then(() => {
          this.reset();

          this.$refs.propertyPrice.setErrors();
          this.$refs.propertyDetails.setErrors();
          this.$refs.propertyAddress.setErrors();
          this.$refs.propertyImages.setErrors();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.$refs.propertyPrice.setErrors(err.response.data.errors);
          this.$refs.propertyDetails.setErrors(err.response.data.errors);
          this.$refs.propertyAddress.setErrors(err.response.data.errors);
          this.$refs.propertyImages.setErrors(err.response.data.errors);

          if (this.errors) {
            this.errorMessage = "Check all steps to see errors";
          }

          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.property = {};

      this.$refs.propertyDetails.reset();
      this.$refs.propertyPrice.reset();
      this.$refs.propertyAddress.reset();
      this.$refs.propertyImages.reset();
      this.$refs.formWrapper.scrollTop = 0;
      this.errorMessage = null;
    },
  },
};
</script>
