<template>
  <div>
    <v-select
      label="Type *"
      v-model="fields.type"
      :items="types"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('type')"
      :error-messages="errors['type']"
    ></v-select>
    <v-select
      label="Status *"
      v-model="fields.status"
      :items="statuses"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('status')"
      :error-messages="errors['status']"
    ></v-select>
    <v-select
      label="Customer *"
      v-model="fields.customer_id"
      :items="customers"
      item-value="id"
      item-text="full_name"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('customer_id')"
      :error-messages="errors['customer_id']"
    ></v-select>
    <v-textarea
      label="Description *"
      v-model="fields.description"
      rows="6"
      outlined
      :error="errors.hasOwnProperty('description')"
      :error-messages="errors['description']"
    ></v-textarea>

    <v-text-field
      label="Total Bedrooms *"
      v-model="fields.total_bed_rooms"
      type="number"
      step="1"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('total_bed_rooms')"
      :error-messages="errors['total_bed_rooms']"
    ></v-text-field>
    <v-text-field
      label="Total Reception Rooms *"
      v-model="fields.total_reception_rooms"
      type="number"
      step="1"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('total_reception_rooms')"
      :error-messages="errors['total_reception_rooms']"
    ></v-text-field>
    <v-text-field
      label="Total Bathrooms *"
      v-model="fields.total_bath_rooms"
      type="number"
      step="1"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('total_bath_rooms')"
      :error-messages="errors['total_bath_rooms']"
    ></v-text-field>
    <v-switch
      label="Has Garden?"
      v-model="fields.has_garden"
      inset
      :error="errors.hasOwnProperty('has_garden')"
      :error-messages="errors['has_garden']"
    ></v-switch>
    <v-switch
      label="Has Garage?"
      v-model="fields.has_garage"
      inset
      :error="errors.hasOwnProperty('has_garage')"
      :error-messages="errors['has_garage']"
    ></v-switch>
    <v-select
      label="Council Tax Band *"
      v-model="fields.council_tax_band"
      :items="councils"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('council_tax_band')"
      :error-messages="errors['council_tax_band']"
    ></v-select>
    <v-select
      label="EPC"
      v-model="fields.epc"
      :items="epc_types"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('epc')"
      :error-messages="errors['epc']"
    ></v-select>
    <v-file-input
      label="Energy Performance Document"
      v-model="fields.energy_performance_document"
      accept="application/pdf"
      placeholder="Choose a File"
      outlined
      :error="errors.hasOwnProperty('energy_performance_document')"
      :error-messages="errors['energy_performance_document']"
    ></v-file-input>
    <v-text-field
      label="viewings performed by*"
      v-model="fields.viewings_performed_by"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('viewings_performed_by')"
      :error-messages="errors['viewings_performed_by']"
    ></v-text-field>
  </div>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  props: {
    property: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      fields: {
        type: null,
        customer_id: null,
        status: null,
        description: null,
        total_bed_rooms: null,
        total_reception_rooms: null,
        total_bath_rooms: null,
        has_garden: false,
        has_garage: false,
        epc: false,
        council_tax_band: null,
        energy_performance_document: null,
      },
      epc_types: ["A", "B", "C", "D", "E", "F", "G"],
      types: [
        "House",
        "Flat/Apartment",
        "Bungalow",
        "Land",
        "Commercial Property",
        "Other",
      ],
      statuses: [
        "For Sale",
        "Under Offer",
        "Sold STC",
        "STCM",
        "Reserved",
        "Complete",
      ],
      councils: ["A", "B", "C", "D", "E", "F", "G", "H", "N/A"],
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },
  },

  methods: {
    setErrors(errors = {}) {
      this.errors = errors;
    },

    reset() {
      this.fields.type = null;
      this.fields.customer_id = null;
      this.fields.description = null;

      this.fields.viewings_performed_by = null;
      this.fields.energy_performance_document = null;
      this.fields.status = null;
      this.fields.total_bed_rooms = null;
      this.fields.total_reception_rooms = null;
      this.fields.total_bath_rooms = null;
      this.fields.has_garden = false;
      this.fields.has_garage = false;
      this.fields.epc = null;
      this.fields.council_tax_band = null;
    },

    error(errors) {
      this.errors = errors;
    },
  },
};
</script>
