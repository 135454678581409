<template>
  <div>
    <v-text-field
      label="Address Line 1 *"
      v-model="fields.address1"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('address1')"
      :error-messages="errors['address1']"
    ></v-text-field>
    <v-text-field
      label="Address Line 2"
      v-model="fields.address2"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('address2')"
      :error-messages="errors['address2']"
    ></v-text-field>
    <v-text-field
      label="City *"
      v-model="fields.city"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('city')"
      :error-messages="errors['city']"
    ></v-text-field>
    <v-text-field
      label="Postcode *"
      v-model="fields.postcode"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('postcode')"
      :error-messages="errors['postcode']"
    ></v-text-field>
    <v-text-field
      label="County"
      v-model="fields.county"
      type="text"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('county')"
      :error-messages="errors['county']"
    ></v-text-field>
    <v-autocomplete
      label="Country *"
      v-model="fields.country"
      :items="availableCountries"
      outlined
      background-color="white"
      :error="errors.hasOwnProperty('country')"
      :error-messages="errors['country']"
    ></v-autocomplete>

    <v-text-field
      label="Slug *"
      v-model="slug"
      type="text"
      outlined
      dense
      background-color="white"
      :error="errors.hasOwnProperty('slug')"
      :error-messages="errors['slug']"
    ></v-text-field>
  </div>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  props: {
    property: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      fields: {
        address1: null,
        address2: null,
        city: null,
        slug: null,
        postcode: null,
        county: null,
        country: "United Kingdom",
      },
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug =
          this.fields.address1 +
          " " +
          this.fields.address2 +
          " " +
          this.fields.city +
          " " +
          this.fields.postcode;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll(null, "");
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ^0-9]/g, "");
        if (this.fields.address1) {
          slug = slug.replaceAll(" ", "-");
        }
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    setErrors(errors = {}) {
      this.errors = errors;
    },

    reset() {
      this.fields.address1 = null;
      this.fields.address2 = null;
      this.fields.slug = null;
      this.fields.city = null;
      this.fields.postcode = null;
      this.fields.county = null;
      this.fields.country = "United Kingdom";
    },

    error(errors) {
      this.errors = errors;
    },
  },
};
</script>
