<template>
  <div>
    <label></label>

    <v-file-input
      label="Upload featured image for this property"
      v-model="fields.featured_image"
      placeholder="Choose a File"
      outlined
      :error="errors.hasOwnProperty('featured_image')"
      :error-messages="errors['featured_image']"
    ></v-file-input>

    <!--<file-pond
      name="file"
      :server="server"
      accepted-file-types="image/*"
      credits="false"
      labelIdle="Drag & Drop your images or <span class='filepond--label-action'> Browse </span>"
      maxFiles="1"
      :files="files"
      :onaddfile="onaddfile"
      :onremovefile="onremovefile"
    />-->
  </div>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,

      fields: {
        featured_image: null,
      },
      errors: {},
    };
  },

  methods: {
    setErrors(errors = {}) {
      this.errors = errors;
    },

    reset() {
      this.fields.featured_image = null;
    },

    error(errors) {
      this.errors = errors;
    },
  },
};
</script>
